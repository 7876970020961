import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import Img, { FixedObject } from "gatsby-image"
import { Player, LoadingSpinner, BigPlayButton, PosterImage } from "video-react"
import "../../node_modules/video-react/dist/video-react.css" // import css

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"

interface Props {
  data: PageQueryData
}

interface PageQueryData {
  strapiHomePage: {
    name: string
    job: string
    about: string
  }
  allStrapiArticle: {
    edges: {
      node: {
        id: string
        title: string
        content: string
        excerpt: string
        image: {
          childImageSharp: {
            fluid: FixedObject
          }
        }
        category: {
          name: string
        }
        author: {
          name: string
        }
        published_at: string
        slug: string
      }
    }
  }
  allStrapiProject: {
    edges: {
      node: {
        id: string
        title: string
        content: string
        excerpt: string
        poster_image: {
          childImageSharp: {
            fluid: FixedObject
          }
          publicURL
        }
        project_video: {
          publicURL: string
        }
        projectcategory: {
          name: string
        }
        published_at: string
        slug: string
      }
    }
  }
}

export const query = graphql`
  query {
    strapiHomePage {
      name
      job
      about
    }
    allStrapiArticle(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          id
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content
          excerpt
          category {
            name
          }
          author {
            name
          }
          published_at
          slug
        }
      }
    }
    allStrapiProject(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          id
          title
          poster_image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          projectcategory {
            name
          }
          project_video {
            publicURL
          }
          slug
          excerpt
          published_at
        }
      }
    }
  }
`

const NameWrapper = styled.h1`
  font-size: 5rem;
  font-family: "Monda";
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 10px;
    background-color: "#333";
    width: 100px;
    height: 100px;
  }
`

const IndexPage: React.FC<Props> = ({ data }) => {
  const articles = data.allStrapiArticle.edges
  const projects = data.allStrapiProject.edges
  return (
    <Layout>
      <SEO title="Home" />

      <div className="relative">
        <h1 className="text-6xl font-monda sm:text-6xl ">JOHN PHUNG</h1>
        <div className="block absolute left-0 -z-1 bg-black w-full h-1/3 top-1/2 opacity-75 sm:top-1/3  sm:h-1/2 sm:w-1/2 " />
        <h3 className="font-monda text-4xl mt-8 ">{data.strapiHomePage.job}</h3>
      </div>

      {/* <Img
        fluid={data.allStrapiArticle.edges[0].node.image.childImageSharp.fluid}
        alt=""
      /> */}
      <div className="border-white border-b-4 mt-16 mb-10 max-w-xl" />
      <p className="font-mont py-8 text-xl md:text-2xl leading-10">
        {data.strapiHomePage.about}
      </p>

      <button className="font-monda z-10 relative  bg-gray-900 p-4 font-bold text-2xl my-8 hover:bg-lightgreen hover:text-black hover:animate-bounce">
        <Link to="/project">SEE MY WORK!</Link>
      </button>

      <section className="block my-8 sm:hidden ">
        <div className="flex items-center justify-between">
          <h3 className="my-0 font-monda text-3xl">
            <Link to="/blog">BLOG</Link>
          </h3>
          <span className="underline font-monda text-xl">
            <Link to="/blog">See All</Link>
          </span>
        </div>
        {articles.map((article, index) => {
          if (index < 5) {
            return (
              <div
                key={article.node.id}
                className="my-6 border-b border-gray-200 pb-6"
              >
                <Img
                  fluid={article.node.image.childImageSharp.fluid}
                  alt={article.node.title}
                />
                <div className="sm:col-start-2 sm:col-span-2">
                  <h4 className="font-monda mt-4 mb-2 text-xl sm:mt-0">
                    <Link to={`/blog/${article.node.slug}`}>
                      {article.node.title}
                    </Link>
                  </h4>

                  <p className="text-sm font-mont lg:text-md">
                    {article.node.excerpt.slice(0, 170) + "..."}
                  </p>
                  <div className="flex flex-col  justify-between sm:flex-row sm:items-center">
                    <div>
                      <p className="my-0 font-mont text-sm font-bold">
                        {article.node.category.name}
                      </p>
                      <p className="my-0 font-mont text-sm">
                        {article.node.author.name} &#x272A; {}
                        {article.node.published_at}
                      </p>
                    </div>
                    <button className="bg-black text-white p-2 text-sm mt-2 sm:mt-0">
                      <Link
                        className="font-mont"
                        to={`/blog/${article.node.slug}`}
                      >
                        Read More &#xbb;
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </section>

      <section className="block my-8 sm:hidden ">
        <div className="flex items-center justify-between">
          <h3 className="my-0 font-ibm">
            <Link to="/project">PROJECTS</Link>
          </h3>
          <span className="underline">
            <Link to="/project">See All</Link>
          </span>
        </div>
        {projects.map((project, index) => {
          if (index < 5) {
            return (
              <div
                key={project.node.id}
                className="my-6 border-b border-gray-200 pb-6"
              >
                <div className="sm:col-start-2 sm:col-span-2">
                  <h4 className="font-monda mt-4 mb-2 text-xl sm:mt-0">
                    <Link to={`/blog/${project.node.slug}`}>
                      {project.node.title}
                    </Link>
                  </h4>

                  <p className="text-sm font-mont lg:text-md">
                    {project.node.excerpt.slice(0, 170) + "..."}
                  </p>
                  <div className="flex flex-col justify-between sm:flex-row sm:items-center">
                    <div>
                      <p className="my-0 font-mont text-sm font-bold">
                        {project.node.projectcategory.name}
                      </p>
                      <p className="my-0 font-mont text-sm">
                        John Phung &#x272A; {}
                        {project.node.published_at}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-4 border-b border-white">
                  <Player
                    src={project.node.project_video.publicURL}
                    poster={project.node.poster_image.publicURL}
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                  </Player>
                  <button className="bg-transparent border-white border text-white p-2 w-full text-sm mt-2 sm:mt-2 focus:outline-none">
                    <Link
                      className="font-mont "
                      to={`/project/${project.node.slug}`}
                    >
                      View Detail &#xbb;
                    </Link>
                  </button>
                </div>
              </div>
            )
          }
        })}
        <p className="font-mont mt-4 mb-2">
          {" "}
          Built with &#10084;&#65039; and &#9749;
        </p>
        <p className="font-mont my-2">Developed from Gatsby and Strapi</p>
      </section>
    </Layout>
  )
}

export default IndexPage

const styles = {}
